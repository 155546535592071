
import React from 'react'
import RegisterDot from "./RegisterDot";


const RegisterArrow = ({next, prev, active, setActive}) => {
    return (
        <div className="btns register-imole ">
            <button className="prev slide-buttons register-btn" onClick={prev}>
            <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.16167 0.836675C9.33724 1.01246 9.43585 1.25074 9.43585 1.49918C9.43585 1.74761 9.33724 1.98589 9.16167 2.16168L3.26167 8.06168H20.9992C21.2478 8.06168 21.4863 8.16045 21.6621 8.33626C21.8379 8.51208 21.9367 8.75054 21.9367 8.99918C21.9367 9.24782 21.8379 9.48627 21.6621 9.66209C21.4863 9.8379 21.2478 9.93668 20.9992 9.93668H3.26167L9.16167 15.8367C9.25378 15.9225 9.32766 16.026 9.3789 16.141C9.43014 16.256 9.45769 16.3801 9.45992 16.506C9.46214 16.6319 9.43898 16.7569 9.39183 16.8737C9.34468 16.9904 9.2745 17.0965 9.18547 17.1855C9.09645 17.2745 8.99041 17.3447 8.87367 17.3918C8.75694 17.439 8.6319 17.4621 8.50602 17.4599C8.38014 17.4577 8.256 17.4301 8.141 17.3789C8.026 17.3277 7.9225 17.2538 7.83668 17.1617L0.336675 9.66168C0.161112 9.48589 0.0625 9.24761 0.0625 8.99918C0.0625 8.75074 0.161112 8.51246 0.336675 8.33668L7.83668 0.836675C8.01246 0.661112 8.25074 0.5625 8.49918 0.5625C8.74761 0.5625 8.98589 0.661112 9.16167 0.836675Z" />
</svg>


            </button>
            <RegisterDot active={active}
            switchSlide={(active) => setActive(active)}  />
            <button className="next slide-buttons register-btn" onClick={next}>
            <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.8383 17.1633C12.6628 16.9875 12.5641 16.7493 12.5641 16.5008C12.5641 16.2524 12.6628 16.0141 12.8383 15.8383L18.7383 9.93832L1.00082 9.93832C0.752184 9.93832 0.513727 9.83955 0.337912 9.66374C0.162096 9.48792 0.063324 9.24946 0.063324 9.00082C0.063324 8.75218 0.162096 8.51373 0.337912 8.33791C0.513727 8.1621 0.752184 8.06332 1.00082 8.06332L18.7383 8.06332L12.8383 2.16332C12.7462 2.0775 12.6723 1.974 12.6211 1.859C12.5699 1.744 12.5423 1.61985 12.5401 1.49398C12.5379 1.3681 12.561 1.24306 12.6082 1.12633C12.6553 1.00959 12.7255 0.903549 12.8145 0.814526C12.9036 0.725502 13.0096 0.655321 13.1263 0.60817C13.2431 0.561018 13.3681 0.537863 13.494 0.540085C13.6199 0.542305 13.744 0.569857 13.859 0.621098C13.974 0.672337 14.0775 0.746214 14.1633 0.838322L21.6633 8.33832C21.8389 8.51411 21.9375 8.75239 21.9375 9.00082C21.9375 9.24926 21.8389 9.48754 21.6633 9.66332L14.1633 17.1633C13.9875 17.3389 13.7493 17.4375 13.5008 17.4375C13.2524 17.4375 13.0141 17.3389 12.8383 17.1633Z" />
</svg>


            </button>
        </div>
    )
}

export default RegisterArrow